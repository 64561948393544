import request from '@/utils/request'


// 一级-设备-查询
export function equipment_get(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/query',
        method: 'post',
        data: data
    })
}


// 一级-设备-创造
export function equipment_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/create',
        method: 'post',
        data: data
    })
}


// 一级-设备-更新
export function equipment_update(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/update',
        method: 'post',
        data: data
    })
}


// 一级-设备-删除
export function equipment_delete(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/delete',
        method: 'post',
        data: data
    })
}


// 设备库分类接口 -------------------------------------------------------------

// 二级-设备分类-查询
export function equipment_type_get(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/query',
        method: 'post',
        data: data
    })
}


// 二级-设备分类-创造
export function equipment_type_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/create',
        method: 'post',
        data: data
    })
}

// 二级-设备分类-更新
export function equipment_type_update(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/update',
        method: 'post',
        data: data
    })
}
// 二级-设备分类-删除
export function equipment_type_delete(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/type/delete',
        method: 'post',
        data: data
    })
}





// 设备品牌分类接口 -------------------------------------------------------------

// 二级-设备品牌-查询
export function equipment_brand_get(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/brand/query',
        method: 'post',
        data: data
    })
}


// 二级-设备品牌-创造
export function equipment_brand_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/brand/create',
        method: 'post',
        data: data
    })
}

// 二级-设备品牌-更新
export function equipment_brand_update(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/brand/update',
        method: 'post',
        data: data
    })
}
// 二级-设备品牌-删除
export function equipment_brand_delete(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/brand/delete',
        method: 'post',
        data: data
    })
}

// 二级-设备资料-查询
export function equipment_material_get(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/query',
        method: 'post',
        data: data
    })
}


// 二级-设备资料-文档-创造
export function equipment_material_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/create',
        method: 'post',
        data: data
    })
}

// 二级-设备资料-文档-更新
export function equipment_material_update(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/update',
        method: 'post',
        data: data
    })
}
// 二级-设备资料-文档-删除
export function equipment_material_delete(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/delete',
        method: 'post',
        data: data
    })
}

// 二级-设备资料-信息
export function equipment_material_info(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/info',
        method: 'post',
        data: data
    })
}

// 三级-设备附件-查询
export function equipment_material_accessory_query(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/accessory/query',
        method: 'post',
        data: data
    })
}


// 三级-设备附件-创造
export function equipment_material_accessory_create(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/accessory/create',
        method: 'post',
        data: data
    })
}


// 三级-设备附件-删除
export function equipment_material_accessory_delete(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/accessory/delete',
        method: 'post',
        data: data
    })
}

// 三级-设备附件内容-获取
export function equipment_material_content_get(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/content/get',
        method: 'post',
        data: data
    })
}
// 三级-设备附件内容-更改
export function equipment_material_content_update(data) {
    return request({
        url: '/platform_api/manage_api/equipment_library/equipment_material/content/update',
        method: 'post',
        data: data
    })
}