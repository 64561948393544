<template>
    <div>
        <el-dialog title="编辑设备" :visible.sync="popov.dialogFormVisibleEdit">
            <el-form :model="formData.edit">
                <el-form-item label="设备id" label-width="120px">
                    <el-input
                        v-model="formData.edit.id"
                        autocomplete="off"
                        :disabled="true"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备名" label-width="120px">
                    <el-input
                        v-model="formData.edit.equipment_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备型号" label-width="120px">
                    <el-input
                        v-model="formData.edit.model_number"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备类型" label-width="120px">
                    <el-select
                        v-model="formData.edit.type_id"
                        placeholder="请选择"
                        filterable
                    >
                        <el-option
                            v-for="item in option.type"
                            :key="item.type_id"
                            :label="item.equipment_type_name"
                            :value="item.type_id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备品牌" label-width="120px">
                    <el-select
                        v-model="formData.edit.brand_id"
                        placeholder="请选择"
                        filterable
                    >
                        <el-option
                            v-for="item in option.brand"
                            :key="item.id"
                            :label="item.brand_name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="popov.dialogFormVisibleEdit = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="sumbitEdit">确 定</el-button>
            </div>
        </el-dialog>

        <div class="commonInputTopBox">
            <infoHeader :content_name="'指定设备的资料'" />
        </div>

        <el-descriptions
            title="设备信息"
            :column="2"
            direction="vertical"
            border
        >
            <template slot="extra">
                <el-button type="primary" @click="openEdit"
                    >编辑设备基础信息</el-button
                >
                <el-button type="danger" @click="sumbitDelete"
                    >删除该设备</el-button
                >
            </template>
            <el-descriptions-item label="设备名">{{
                materialEquipment.equipment_name
            }}</el-descriptions-item>
            <el-descriptions-item label="设备类型">{{
                materialEquipment.equipment_type.equipment_type_name
            }}</el-descriptions-item>
            <el-descriptions-item label="生产商">{{
                materialEquipment.model_brand.brand_name
            }}</el-descriptions-item>
            <el-descriptions-item label="型号">{{
                materialEquipment.model_number
            }}</el-descriptions-item>
        </el-descriptions>

        <!-- 产品 -->
        <el-descriptions title="设备文档" style="margin-top: 30px">
            <template slot="extra">
                <el-button
                    type="primary"
                    size="small"
                    @click="createNewMaterial"
                    >创建新的资料</el-button
                >
            </template>
        </el-descriptions>

        <template>
            <el-table :data="materialList" style="width: 100%">
                <el-table-column prop="id" label="系统id" width="180">
                </el-table-column>
                <el-table-column prop="material_title" label="资料标题">
                </el-table-column>
                <el-table-column prop="create_time" label="创建时间">
                </el-table-column>
                <el-table-column prop="update_time" label="最近一次更新时间">
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="editMaterial(scope.row)"
                            >重命名</el-button
                        >
                        <el-button size="mini" @click="toMaterial(scope.row)"
                            >查看资料</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </template>
    </div>
</template>

<script>
import {
    equipment_update,
    equipment_material_create,
    equipment_material_update,
    equipment_material_get,
    equipment_material_info,
    equipment_type_get,
    equipment_brand_get,
    equipment_delete
} from '@/api/manage/operation/equipment/equipment.js';
export default {
    name: 'start',
    data() {
        return {
            infoData: this.$route.query,
            materialEquipment: {
                equipment_type: {},
                model_brand: {}
            },
            materialList: [],
            query: {},
            popov: {
                dialogFormVisibleEdit: false
            },
            formData: {
                edit: {}
            },
            option: {
                type: [],
                brand: []
            }
        };
    },
    components: {},
    created() {
        this.infoData = this.$route.query;
        this.getMaterialData();
        this.getMaterialList();
        this.initOption();
    },
    methods: {
        initOption() {
            equipment_type_get(this.query).then(res => {
                if (res.code == 200) {
                    this.option.type = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            });
            equipment_brand_get(this.query).then(res => {
                if (res.code == 200) {
                    this.option.brand = res.data;
                } else {
                    this.$message.info(res.msg);
                }
            });
        },
        getMaterialList() {
            equipment_material_get({
                equipment_id: this.infoData.equipment_id
            }).then(res => {
                this.materialList = res.data;
            });
        },
        getMaterialData() {
            equipment_material_info({
                id: this.infoData.equipment_id
            }).then(res => {
                this.materialEquipment = res.data;
            });
        },
        editMaterial(row) {
            this.$prompt('修改资料标题', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then(({ value }) => {
                    equipment_material_update({
                        equipment_material_id: row.id,
                        material_title: value
                    }).then(res => {
                        if (res.code == 200) {
                            this.getMaterialData();
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
        },
        createNewMaterial() {
            this.$prompt('要创建的文档名', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then(({ value }) => {
                    equipment_material_create({
                        equipment_id: this.infoData.id,
                        material_title: value
                    }).then(() => {
                        this.getMaterialData();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
        },
        openEdit() {
            this.formData.edit.id = this.infoData.id;
            this.formData.edit.equipment_name = this.infoData.equipment_name;
            this.formData.edit.model_number = this.infoData.model_number;
            this.popov.dialogFormVisibleEdit = true;
            console.log(this.formData);
        },
        sumbitEdit() {
            Reflect.deleteProperty(this.formData.edit, 'equipment_type');
            Reflect.deleteProperty(this.formData.edit, 'model_brand');
            Reflect.deleteProperty(this.formData.edit, 'product_type');
            this.formData.edit['annotation'] = '';

            this.formData.edit['equipment_id'] = this.formData.edit['id'];
            // Reflect.deleteProperty(this.formEdit,"id")
            Reflect.deleteProperty(this.formData.edit, 'id');
            console.log(this.formData.edit);
            equipment_update(this.formData.edit).then(res => {
                if (res.code == 200) {
                    this.$message.success('成功修改');
                    this.$router.go(-1);
                } else {
                    this.$message.info(res.msg);
                }
            });
            this.dialogFormVisibleEdit = false;
        },
        sumbitDelete() {
            this.$confirm('是否确定删除', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '删除',
                cancelButtonText: '放弃删除'
            })
                .then(() => {
                    equipment_delete({
                        equipment_id: this.infoData.id
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('成功删除');
                            this.$router.go(-1);
                        } else {
                            this.$message.info(res.msg);
                        }
                    });
                })
                .catch(action => {
                    this.$message({
                        type: 'info',
                        message: action === 'cancel' ? '放弃删除' : '停留在当前'
                    });
                });
        },
        toMaterial(item) {
            this.$router.push({ name: 'equipment_materia', query: item });
        }
    }
};
</script>

<style lang="scss" scoped>
.commonControl-body {
    el-descriptions {
        margin-top: 20px;
    }
}
</style>
